import * as PIXI from 'pixi.js';

const app = new PIXI.Application({ resizeTo: window });

document.body.appendChild(app.view);

// create a new background sprite
const background = PIXI.Sprite.from('https://pixijs.com/assets/bg_rotate.jpg');
// Scale mode for all textures, will retain pixelation
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
const sprite = PIXI.Sprite.from('https://pixijs.com/assets/bunny.png');

// Set the initial position
sprite.anchor.set(0.5);
sprite.x = app.screen.width / 2;
sprite.y = app.screen.height / 2;

// Opt-in to interactivity
sprite.interactive = true;

// Shows hand cursor
sprite.cursor = 'pointer';

// Pointers normalize touch and mouse
sprite.on('pointerdown', onClick);

// Alternatively, use the mouse & touch events:
// sprite.on('click', onClick); // mouse-only
// sprite.on('tap', onClick); // touch-only
//
function dudeClick(event)
{
    dudeArray[ event.target.dudeIndex ].paused = ! dudeArray[ event.target.dudeIndex ].paused
}


function onClick()
{
    sprite.scale.x *= 1.25;
    sprite.scale.y *= 1.25;
}

background.width = app.screen.width;
background.height = app.screen.height;
app.stage.addChild(background);
app.stage.addChild(sprite);

// create an array to store a reference to the dudes
const dudeArray = [];

const totaldudes = 8;

for (let i = 0; i < totaldudes; i++)
{
    // create a new Sprite that uses the image name that we just generated as its source
    const dude = PIXI.Sprite.from('https://pixijs.com/assets/flowerTop.png');
        dude.dudeIndex = i
        dude.interactive = true;
        dude.cursor = 'pointer';
        dude.on('pointerdown', dudeClick);

    dude.anchor.set(0.5);

    // set a random scale for the dude
    dude.scale.set(1.0 + Math.random() * 0.3);

    // finally let's set the dude to be at a random position...
    dude.x = Math.floor(Math.random() * app.screen.width);
    dude.y = Math.floor(Math.random() * app.screen.height);

    // The important bit of this example, this is how you change the default blend mode of the sprite
    dude.blendMode = PIXI.BLEND_MODES.ADD;

    // create some extra properties that will control movement
    dude.direction = Math.random() * Math.PI * 2;

    // this number will be used to modify the direction of the dude over time
    dude.turningSpeed = Math.random() - 0.8;

    // create a random speed for the dude between 0 - 2
    dude.speed = 2 + Math.random() * 2;

    // finally we push the dude into the dudeArray so it it can be easily accessed later
    dudeArray.push(dude);

    app.stage.addChild(dude);
}

// create a bounding box for the little dudes
const dudeBoundsPadding = 100;

const dudeBounds = new PIXI.Rectangle(
    -dudeBoundsPadding,
    -dudeBoundsPadding,
    app.screen.width + dudeBoundsPadding * 2,
    app.screen.height + dudeBoundsPadding * 2,
);

const style = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 72,
    fontStyle: 'italic',
    fontWeight: 'bold',
    fill: ['#ffffff', '#00ff99'], // gradient
    stroke: '#4a1850',
    strokeThickness: 5,
    dropShadow: true,
    dropShadowColor: '#000000',
    dropShadowBlur: 4,
    dropShadowAngle: Math.PI / 6,
    dropShadowDistance: 6,
    wordWrap: true,
    wordWrapWidth: 440,
    lineJoin: 'round',
});
const label = new PIXI.Text('Counter:', style);
label.x = 100;
label.y = 100;
app.stage.addChild(label);
const basicText = new PIXI.Text('Basic text in pixi', style);
basicText.x = 450;
basicText.y = 100;
app.stage.addChild(basicText);

let tick = 1

app.ticker.add(() =>
{
    basicText.text = tick
    tick += 1;

    // iterate through the dudes and update the positions
    for (let i = 0; i < dudeArray.length; i++)
    {
        const dude = dudeArray[i];

        if (dude.paused) {
            continue
        }

        dude.direction += dude.turningSpeed * 0.01;
        dude.x += Math.sin(dude.direction) * dude.speed;
        dude.y += Math.cos(dude.direction) * dude.speed;
        dude.rotation = -dude.direction - Math.PI / 2;

        // wrap the dudes by testing their bounds...
        if (dude.x < dudeBounds.x)
        {
            dude.x += dudeBounds.width;
        }
        else if (dude.x > dudeBounds.x + dudeBounds.width)
        {
            dude.x -= dudeBounds.width;
        }

        if (dude.y < dudeBounds.y)
        {
            dude.y += dudeBounds.height;
        }
        else if (dude.y > dudeBounds.y + dudeBounds.height)
        {
            dude.y -= dudeBounds.height;
        }
    }
});
